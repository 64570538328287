import React from 'react';
import moment from 'moment';

import { Article } from '../../models/article';

export function ArticleComponent(props: { article: Article }) {
  const getDate = (timestamp: string): string => {
    return moment(Number(timestamp)).format('ll');
  };

  const article = props.article;

  return (
    <div data-id={article.id} data-created={getDate(article.created)}>
      <div
        className="article-picture"
        style={{ backgroundImage: `url(${article.picture})` }}
      />
      <div className="article-content">
        <ul className="article-keywords">
          {article.keywords.split(',').map(keyword => (
            <li key={keyword.toString()}>{keyword}</li>
          ))}
        </ul>
        <p className="article-title" title={article.title}>
          {article.title}
        </p>

        <a target="_blank" href={article.link}>
          Lire l'article
        </a>
      </div>
    </div>
  );
}
