import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  articleReducer,
  State as ArticlesState,
  initialState as articlesInitialState,
} from './article';

import {
  filterReducer,
  State as FiltersState,
  initialState as filtersInitialState,
} from './filter';

export interface State {
  articles: ArticlesState;
  filters: FiltersState;
}

export const initialState: State = {
  articles: articlesInitialState,
  filters: filtersInitialState,
};

const rootReducer = combineReducers({
  articles: articleReducer,
  filters: filterReducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['filters'],
};

export default persistReducer(persistConfig, rootReducer);
