import React, { useState, useCallback } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { FilterAction } from '../../store/actions/filter';
import { State } from '../../store/reducers';
import { State as FiltersState } from '../../store/reducers/filter';
import { ArticleType } from '../../models/article-type';
import { ArticleTag } from '../../models/article-tag';
import { getType, getTag } from '../../utils/matchers';

import './filters.scss';
import Checkbox from './checkbox';

interface ArticleFilterProps {
  typeChanged: (type: ArticleType) => void;
}

export function ArticleFilter(props: ArticleFilterProps) {
  const dispatch = useDispatch();
  const { typeChanged } = props;

  const mapState = useCallback(
    (state: State) => ({
      filters: state.filters,
    }),
    []
  );

  const { filters }: { filters: FiltersState } = useMappedState(mapState);

  const [tags, setTags] = useState(filters.tags);
  const [type, setType] = useState(filters.type);
  const [search, setSearch] = useState(filters.search);

  const checkboxes: any[] = [
    {
      value: ArticleTag.Dev,
      text: 'Dev',
      key: 'dev',
    },
    {
      value: ArticleTag.Ux,
      text: 'UX',
      key: 'ux',
    },
    {
      value: ArticleTag.Ui,
      text: 'UI',
      key: 'ui',
    },
    {
      value: ArticleTag.Po,
      text: 'PO',
      key: 'po',
    },
    {
      value: ArticleTag.Other,
      text: 'Autre',
      key: 'other',
    },
  ];

  function toggleTag(value: ArticleTag) {
    let tagsToSet = tags.includes(value)
      ? tags.filter(tag => tag !== value)
      : tags.concat(value);

    setTags(tagsToSet);

    dispatch({
      type: FilterAction.FILTER_CHANGE_TAGS,
      payload: tagsToSet,
    });
  }

  function tagExists(tag: ArticleTag): boolean {
    return tags.includes(tag);
  }

  function updateType(value: ArticleType) {
    setType(value);
    dispatch({
      type: FilterAction.FILTER_CHANGE_TYPE,
      payload: value,
    });
    typeChanged(value);
  }

  function updateSearch(value: string) {
    setSearch(value);
    dispatch({
      type: FilterAction.FILTER_UPDATE_SEARCH,
      payload: value,
    });
  }

  return (
    <div className="filter-bar">
      <div className="filter-input search-input">
        <input
          placeholder="Recherche par titre ou par mots-clés"
          type="text"
          id="search"
          value={search}
          onChange={e => updateSearch(e.target.value)}
        />
      </div>

      <div className="flex-bar">
        <span>Filter par :</span>

        <div id="filter-category" className="filter-input">
          {checkboxes.map(item => (
            <label key={item.key}>
              <Checkbox
                name={item.name}
                checked={tagExists(item.value)}
                onChange={e => toggleTag(getTag(item.value))}
              />
              <span>{item.text}</span>
            </label>
          ))}
        </div>

        <div className="filter-input">
          <select
            value={type}
            onChange={e => updateType(getType(e.target.value))}
          >
            <option value="article">Article</option>
            <option value="talk">Talk</option>
          </select>
        </div>
      </div>
    </div>
  );
}
