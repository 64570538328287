import React from 'react';
import { Link } from 'react-router-dom';

import './header.scss';

export function AdminHeaderComponent() {
  return (
    <div className="page-top">
      <Link to="/" className="page-title">
        <h1>Monty</h1>
        <h2>La veille d'UX-Republic</h2>
      </Link>

      <nav>
        <button className="link-button reversed">
          <Link to="/">Retour à la liste</Link>
        </button>
        <button className="link-button">
          <Link to="/add">Ajouter un article</Link>
        </button>
      </nav>
    </div>
  );
}
