import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { isNullOrUndefined } from 'util';

import { databaseRef } from '../../config/firebase';
import { Article } from '../../models/article';
import { ArticleAction } from '../../store/actions';
import { State } from '../../store/reducers';
import { State as ArticleState } from '../../store/reducers/article';
import { isAdminLogged } from '../../utils/authentication';
import { getArticlesFromQuery } from '../../utils/load-articles';
import { AdminHeaderComponent } from '../../components/headers/AdminHeader';
import { ArticleComponent } from '../../components/articles/Article';

export const Admin = withRouter(({ history }) => {
  const dispatch = useDispatch();

  const mapState = useCallback(
    (state: State) => ({
      articles: state.articles,
    }),
    []
  );

  const { articles }: { articles: ArticleState } = useMappedState(mapState);

  function acceptArticle(article: Article) {
    databaseRef
      .collection('articles')
      .doc(article.id)
      .set({
        ...article,
        accepted: true,
      })
      .then(loadArticles);
  }

  function removeArticle(article: Article) {
    databaseRef
      .collection('articles')
      .doc(article.id)
      .delete()
      .then(loadArticles);
  }

  function dispatchArticles(payload: Article[]) {
    dispatch({
      type: ArticleAction.ARTICLES_LOADED,
      payload,
    });
  }

  function loadArticles() {
    databaseRef
      .collection('articles')
      .where('accepted', '==', false)
      .get()
      .then(querySnapshot => {
        dispatchArticles(getArticlesFromQuery(querySnapshot));
      });

    // databaseRefMock.articles().then((articles: Article[]) => {
    //   dispatchArticles(articles);
    // });
  }

  function verifyUserLogin(): Promise<boolean> {
    if (isNullOrUndefined(localStorage.getItem('montyCode'))) {
      history.push('/adminlogin');
    }

    return isAdminLogged();
    // return new Promise(resolve => resolve(true));
  }

  useEffect(() => {
    verifyUserLogin().then(isAuthenticated => {
      if (!isAuthenticated) {
        history.push('/adminlogin');
      }

      loadArticles();
    });
  }, []);

  return (
    <div>
      <AdminHeaderComponent />

      <h3>Espace Administrateur</h3>
      <h4>Les articles à valider</h4>

      {articles.length ? (
        <ul className="articles">
          {articles.map(article => (
            <li className="article" key={article.title.toString()}>
              <button className="btn" onClick={() => acceptArticle(article)}>
                Valider cet article
              </button>
              <button className="btn" onClick={() => removeArticle(article)}>
                Supprimer cet article
              </button>
              <ArticleComponent article={article} />
            </li>
          ))}
        </ul>
      ) : (
        <p>Aucun article proposé dans l'immédiat</p>
      )}
    </div>
  );
});
