import { Article } from '../../models/article';

export enum ArticleAction {
  ARTICLES_LOADED = 'ARTICLES_LOADED',
}

export interface ArticlesLoaded {
  type: ArticleAction.ARTICLES_LOADED;
  payload: Article[];
}

export type ArticleActions = ArticlesLoaded;
