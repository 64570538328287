import * as firebase from 'firebase/app';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: 'AIzaSyDiN-Q4ybwftMzLUVThz3e_1BVBhLUUSOM',
  authDomain: 'monty-48cd0.firebaseapp.com',
  databaseURL: 'https://monty-48cd0.firebaseio.com',
  projectId: 'monty-48cd0',
  storageBucket: 'monty-48cd0.appspot.com',
  messagingSenderId: '318837393834',
};
firebase.initializeApp(firebaseConfig);
export const databaseRef = firebase.firestore();
