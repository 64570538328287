import 'firebase/firestore';

import React, { useCallback, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { isNullOrUndefined } from 'util';

import { ArticleComponent } from '../components/articles/Article';
import { HeaderComponent } from '../components/headers/Header';
import { ArticleFilter } from '../components/filters/ArticleFilter';
import { databaseRef } from '../config/firebase';
import { Article } from '../models/article';
import { ArticleAction } from '../store/actions';
import { State } from '../store/reducers';
import { isUserLogged } from '../utils/authentication';
import {
  filterArticlesByTag,
  filterArticlesBySearchOrKeywords,
} from '../utils/filters';
import { getArticlesFromQuery } from '../utils/load-articles';
import { ArticleType } from '../models/article-type';
import { FooterComponent } from '../components/footer/Footer';

export const Home = withRouter(({ history }) => {
  const dispatch = useDispatch();

  const mapState = useCallback(
    (state: State) => ({
      articles: state.articles,
      filters: state.filters,
    }),
    []
  );

  const isAdmin = !isNullOrUndefined(localStorage.getItem('montyCode'));

  const { articles, filters }: State = useMappedState(mapState);

  const filteredArticles = (): Article[] => {
    return articles
      .filter(article => filterArticlesByTag(article, filters))
      .filter(article => filterArticlesBySearchOrKeywords(article, filters));
  };

  function dispatchArticles(payload: Article[]) {
    dispatch({
      type: ArticleAction.ARTICLES_LOADED,
      payload,
    });
  }

  function onTypeChanged(type: ArticleType) {
    loadArticles(type);
  }

  function loadArticles(type = '') {
    const filterType = type || filters.type;

    if (isUserLogged()) {
      databaseRef
        .collection('articles')
        .where('accepted', '==', true)
        .where('type', '==', filterType)
        .orderBy('created', 'desc')
        // .limit(20)
        .get()
        .then(querySnapshot => {
          dispatchArticles(getArticlesFromQuery(querySnapshot));
        });
    } else {
      history.push('/login');
    }

    // databaseRefMock.articles().then((articles: Article[]) => {
    //   dispatchArticles(articles);
    // });
  }

  useEffect(() => loadArticles(), []);

  return (
    <div>
      <HeaderComponent />
      <ArticleFilter typeChanged={onTypeChanged} />

      {filteredArticles().length ? (
        <div className="container">
          <h3>Les derniers articles partagés</h3>
          <ul className="articles">
            {filteredArticles().map(article => (
              <li className="article" key={article.title.toString()}>
                <ArticleComponent article={article} />
                {isAdmin ? (
                  <button className="back-button edit-button">
                    <Link to={`edit/${article.id}`}>Edit Article</Link>
                  </button>
                ) : (
                  <span />
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Aucun article ne correspond à la recherche</p>
      )}
      <FooterComponent />
    </div>
  );
});
