import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { isNullOrUndefined } from 'util';

import { ArticleForm } from '../../components/articles/ArticleForm';
import { databaseRef } from '../../config/firebase';
import { Article } from '../../models/article';
import { isAdminLogged } from '../../utils/authentication';
import { AdminHeaderComponent } from '../../components/headers/AdminHeader';

export const AddArticle = withRouter(({ history }) => {
  const addArticle = (article: Article) => {
    databaseRef
      .collection('articles')
      .doc()
      .set({
        ...article,
        accepted: true,
      })
      .then(() => {
        toast('Votre article a bien été ajouté !');
      })
      .catch(() => toast("Problème lors de l'ajout de votre article"));
  };

  function verifyUserLogin(): Promise<boolean> {
    if (isNullOrUndefined(localStorage.getItem('montyCode'))) {
      history.push('/adminlogin');
    }

    return isAdminLogged();
    // return new Promise(resolve => resolve(true));
  }

  useEffect(() => {
    verifyUserLogin().then(isAuthenticated => {
      if (!isAuthenticated) {
        history.push('/adminlogin');
      }
    });
  });

  return (
    <div>
      <AdminHeaderComponent />

      <div className="container">
        <h3>Ajouter un article</h3>
        <div className="container">
          <ArticleForm editMode={false} onArticleCompleted={addArticle} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
});
