import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ArticleForm } from '../components/articles/ArticleForm';
import { databaseRef } from '../config/firebase';
import { Article } from '../models/article';
import { isUserLogged } from '../utils/authentication';
import { HeaderComponent } from '../components/headers/Header';

export const ProposeArticle = withRouter(({ history }) => {
  const addArticle = (article: Article) => {
    databaseRef
      .collection('articles')
      .doc()
      .set({
        ...article,
        accepted: false,
      })
      .then(() => toast('Votre article a bien été proposé !'))
      .catch(() => toast('Problème lors de la proposition de votre article'));
  };

  function checkUserLogin() {
    if (!isUserLogged()) {
      history.push('/login');
    }
  }

  useEffect(() => checkUserLogin(), []);

  return (
    <div>
      <HeaderComponent />
      <div className="container">
        <h3>Proposer un article</h3>
        <div className="container">
          <ArticleForm editMode={false} onArticleCompleted={addArticle} />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
});
