import React from 'react';

const Radio = ({
  type = 'radio',
  name = '',
  value = '',
  onChange = (e: any) => {},
}) => <input type={type} name={name} value={value} onChange={onChange} />;

export default Radio;
