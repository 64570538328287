import { ArticleAction, ArticleActions } from '../actions';
import { Article } from '../../models/article';

export type State = Article[];
export const initialState: State = [];

export const articleReducer = (
  state: State = initialState,
  action: ArticleActions
): State => {
  switch (action.type) {
    case ArticleAction.ARTICLES_LOADED:
      return action.payload;
    default:
      return state;
  }
};
