import React, { FormEvent, useState, useEffect } from 'react';

import { Article } from '../../models/article';
import { getTag, getType } from '../../utils/matchers';
import { ArticleType } from '../../models/article-type';
import { ArticleTag } from '../../models/article-tag';
import Radio from '../filters/radio';

interface ArticleFormProps {
  onArticleCompleted: (article: Article) => void;
  currentArticle?: Article;
  editMode: boolean;
}

export function ArticleForm(props: ArticleFormProps) {
  const { editMode, onArticleCompleted } = props;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [picture, setPicture] = useState('');
  const [tag, setTag] = useState(ArticleTag.Dev);
  const [keywords, setKeywords] = useState('');
  const [type, setType] = useState(ArticleType.Article);

  const radios: any[] = [
    {
      value: ArticleTag.Dev,
      text: 'Développement',
      key: 'dev',
    },
    {
      value: ArticleTag.Ux,
      text: 'UX',
      key: 'ux',
    },
    {
      value: ArticleTag.Ui,
      text: 'UI',
      key: 'ui',
    },
    {
      value: ArticleTag.Po,
      text: 'PO',
      key: 'po',
    },
    {
      value: ArticleTag.Other,
      text: 'Autre',
      key: 'other',
    },
  ];

  const formHasErrors = (): boolean => {
    return title === '' || link === '';
  };

  const submitForm = (event: FormEvent) => {
    event.preventDefault();

    const tagValue = getTag(tag);
    const typeValue = getType(type);
    const date = new Date();
    const timestamp = date.getTime();

    const article: Article = {
      title,
      type: typeValue,
      description,
      link,
      picture,
      tag: tagValue,
      keywords,
      created: timestamp.toString(),
    };

    onArticleCompleted(article);
  };

  function updateArticleProperties(currentArticle: Article | undefined): void {
    setTitle(props.currentArticle ? props.currentArticle.title : '');
    setDescription(
      props.currentArticle ? props.currentArticle.description : ''
    );
    setLink(props.currentArticle ? props.currentArticle.link : '');
    setPicture(props.currentArticle ? props.currentArticle.picture : '');
    setTag(props.currentArticle ? props.currentArticle.tag : ArticleTag.Dev);
    setKeywords(props.currentArticle ? props.currentArticle.keywords : '');
    setType(
      props.currentArticle ? props.currentArticle.type : ArticleType.Article
    );
  }

  useEffect(() => {
    updateArticleProperties(props.currentArticle);
  }, [props]);

  return (
    <form onSubmit={submitForm}>
      <div className="form-input form-input-inline">
        <label htmlFor="tag">
          Catégorie <span className="required-field">*</span>
        </label>
        <div id="tags-radio">
          {radios.map(item => (
            <label key={item.key}>
              <Radio
                name="radio-tag"
                value={item.key}
                onChange={e => setTag(item.value)}
              />
              <span>{item.text}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="form-input">
        <label htmlFor="title">
          Titre <span className="required-field">*</span>
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div className="form-input">
        <label htmlFor="link">
          URL <span className="required-field">*</span>
        </label>
        <input
          type="text"
          id="link"
          value={link}
          onChange={e => setLink(e.target.value)}
        />
      </div>
      <div className="form-input">
        <label htmlFor="picture">
          Image d'illustration (URL) <span className="required-field">*</span>
        </label>
        <input
          type="text"
          id="picture"
          value={picture}
          onChange={e => setPicture(e.target.value)}
        />
      </div>
      <div>
        <img src={picture} width="100" alt="" />
      </div>
      <div className="form-input">
        <label htmlFor="type">
          Type de contenu <span className="required-field">*</span>
        </label>
        <select
          id="type"
          value={type}
          onChange={e => setType(getType(e.target.value))}
        >
          <option value="article">Article</option>
          <option value="talk">Talk</option>
        </select>
      </div>

      <div className="form-input">
        <label htmlFor="keywords">
          <span>Mots-clés</span>
          <br />
          <span className="hint-text">
            Exemple de format : design,illustrator,vectoriel
          </span>
        </label>
        <input
          type="text"
          id="keywords"
          value={keywords}
          onChange={e => setKeywords(e.target.value)}
        />
      </div>

      {formHasErrors() ? (
        <div>
          <p className="error-message">
            Au moins un champ obligatoire n'a pas été rempli
          </p>
          <button className="save-button" disabled>
            Ajouter
          </button>
        </div>
      ) : (
        <div>
          {editMode ? (
            <button className="save-button">Mettre à jour</button>
          ) : (
            <button className="save-button">Ajouter</button>
          )}
        </div>
      )}
    </form>
  );
}
