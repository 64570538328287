import * as firebase from 'firebase/app';
import 'firebase/auth';

import { databaseRef } from '../config/firebase';
import { ErrorCode } from '../models/error-code';

export function isAdminLogged(): Promise<boolean> {
  return new Promise(resolve => {
    let isAuthenticated = false;
    databaseRef
      .collection('users')
      .get()
      .then(users => {
        users.forEach(function(doc) {
          const user = doc.data();

          if (localStorage.getItem('montyCode') === user.code) {
            isAuthenticated = true;
          }
        });

        resolve(isAuthenticated);
      });
  });
}

export function isUserLogged(): boolean {
  return localStorage.getItem('montyUserToken') !== null;
}

export function logUser(): Promise<void> {
  return new Promise((resolve, reject) => {
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result: any) => {
        const token = result.credential.accessToken;
        const user = result.user;

        if (user.email.includes('@ux-republic.com')) {
          localStorage.setItem('montyUserToken', token);
          resolve();
        } else {
          reject({
            code: ErrorCode.USER_LOGIN_WITHOUT_UX_REPUBLIC_ADDRESS,
            message:
              'Il faut vous connecter avec une adresse mail ux-republic.com',
          });
        }
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = error.credential;

        console.log('errorCode', errorCode);
        console.log('errorMessage', errorMessage);
        console.log('email', email);
        console.log('credential', credential);

        reject({
          code: ErrorCode.USER_LOGIN_FAILED,
          message: "Problème lors de l'authentification. Veuillez réessayer.",
        });
      });
  });
}
