import { Article } from '../models/article';

import { State as FiltersState } from '../store/reducers/filter';

export const filterArticlesByTag = (
  article: Article,
  filters: FiltersState
): boolean => {
  return filters.tags.includes(article.tag);
};

export const filterArticlesByKeywords = (
  article: Article,
  filters: FiltersState
): boolean => {
  return Boolean(
    article.keywords
      .split(',')
      .find(keyword => keyword.includes(filters.search))
  );
};

export const filterArticlesBySearchOrKeywords = (
  article: Article,
  filters: FiltersState
): boolean => {
  if (filters.search) {
    const search = filters.search.toLowerCase();
    const title = article.title.toLowerCase();
    const description = article.description.toLowerCase();

    return (
      title.includes(search) ||
      description.includes(search) ||
      filterArticlesByKeywords(article, filters)
    );
  }

  return true;
};
