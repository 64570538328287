import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { logUser, isUserLogged } from '../utils/authentication';

import './login.scss';

export const UserLogin = withRouter(({ history }) => {
  const [loginError, setLoginError] = useState('');

  function logInUser(): any {
    setLoginError('');

    logUser()
      .then(() => {
        history.push('/');
      })
      .catch((error: any) => {
        console.log('error', error);
        setLoginError(error.message);
      });
  }

  function checkUserLogin() {
    if (isUserLogged()) {
      history.push('/');
    }
  }

  useEffect(() => checkUserLogin(), []);

  return (
    <div className="login">
      <h1>Monty</h1>
      <h2>La veille d'UX-Republic</h2>

      <button className="btn" onClick={e => logInUser()}>
        Connexion membre UX-Republic
      </button>

      <p>{loginError}</p>
    </div>
  );
});
