import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Home } from './pages/Home';
import { AddArticle } from './pages/admin/AddArticle';
import './App.scss';
import { ProposeArticle } from './pages/ProposeArticle';
import { Admin } from './pages/admin/Admin';
import { AdminLogin } from './pages/admin/AdminLogin';
import { UserLogin } from './pages/UserLogin';
import { EditArticle } from './pages/admin/EditArticle';

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route path="/add" component={AddArticle} />
        <Route path="/edit/:id" component={EditArticle} />
        <Route path="/propose" component={ProposeArticle} />
        <Route path="/admin" component={Admin} />
        <Route path="/adminlogin" component={AdminLogin} />
        <Route path="/login" component={UserLogin} />
      </Router>
    );
  }
}

export default App;
