import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isNullOrUndefined } from 'util';

import { ArticleForm } from '../../components/articles/ArticleForm';
import { isAdminLogged } from '../../utils/authentication';
import { databaseRef } from '../../config/firebase';
import { Article } from '../../models/article';
import { toast, ToastContainer } from 'react-toastify';

export const EditArticle = withRouter(({ history }) => {
  const routeElements = history.location.pathname.split('/');
  const articleId = routeElements[routeElements.length - 1];

  const [article, setArticle] = useState(undefined);

  function verifyUserLogin(): Promise<boolean> {
    if (isNullOrUndefined(localStorage.getItem('montyCode'))) {
      history.push('/adminlogin');
    }

    return isAdminLogged();
  }

  function getArticle(): void {
    databaseRef
      .collection('articles')
      .doc(articleId)
      .get()
      .then((querySnapshot: any) => {
        setArticle(querySnapshot.data());
      });
  }

  const editArticle = (article: Article) => {
    databaseRef
      .collection('articles')
      .doc(articleId)
      .set({
        ...article,
        accepted: true,
      })
      .then(() => {
        toast('Votre article a bien été édité !');
      })
      .catch(() => toast("Problème lors de l'édition de votre article"));
  };

  useEffect(() => {
    verifyUserLogin().then(isAuthenticated => {
      if (!isAuthenticated) {
        history.push('/adminlogin');
      }

      getArticle();
    });
  }, []);

  return (
    <div>
      <h1>Edition d'un article</h1>
      <button className="back-button">
        <Link to="/">Retour à la liste des articles</Link>
      </button>
      <ArticleForm
        currentArticle={article}
        editMode={true}
        onArticleCompleted={editArticle}
      />
      <ToastContainer />
    </div>
  );
});
