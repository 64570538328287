import Base64 from 'crypto-js/enc-base64';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import sha256 from 'crypto-js/sha256';
import React, { FormEvent, useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import { databaseRef } from '../../config/firebase';
import { isUserLogged } from '../../utils/authentication';

export const AdminLogin = withRouter(({ history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  let showError = false;

  function submitForm(event: FormEvent) {
    event.preventDefault();

    const hashDigest = sha256(password).toString();
    const encryptedPassword = Base64.stringify(hmacSHA512(hashDigest, 'roti'));

    databaseRef
      .collection('users')
      .get()
      .then(users => {
        users.forEach(doc => {
          const user = doc.data();

          if (
            user.username === username &&
            user.password === encryptedPassword
          ) {
            localStorage.setItem('montyCode', user.code);
            history.push('/admin');
          }
        });

        showError = true;
      });
  }

  function checkUserLogin() {
    if (!isUserLogged()) {
      history.push('/login');
    }
  }

  useEffect(() => checkUserLogin(), []);

  return (
    <div>
      <h1>Admin login</h1>
      <form onSubmit={submitForm}>
        <div className="form-input">
          <label htmlFor="username">Nom d'utilisateur</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </div>
        <div className="form-input">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        {showError ? (
          <p>Vos identifiants de connexion ne correspondent pas.</p>
        ) : (
          <span />
        )}
        <button className="save-button">Se connecter</button>
      </form>
    </div>
  );
});
