import { ArticleType } from '../models/article-type';
import { ArticleTag } from '../models/article-tag';

export const getType = (value: string): ArticleType => {
  switch (value) {
    case 'talk':
      return ArticleType.Talk;
    case 'article':
      return ArticleType.Article;
    default:
      return ArticleType.Article;
  }
};

export const getTag = (tag: string): ArticleTag => {
  switch (tag) {
    case 'dev':
      return ArticleTag.Dev;
    case 'ux':
      return ArticleTag.Ux;
    case 'ui':
      return ArticleTag.Ui;
    case 'po':
      return ArticleTag.Po;
    case 'all':
      return ArticleTag.All;
    default:
      return ArticleTag.Other;
  }
};

export const getCategory = (tag: ArticleTag) => {
  switch (tag) {
    case ArticleTag.Dev:
      return 'Développement';
    case ArticleTag.Ux:
      return 'User Experience';
    case ArticleTag.Ui:
      return 'User Interface';
    default:
      return 'Autre';
  }
};
