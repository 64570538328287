import { Article } from '../models/article';

export const getArticlesFromQuery = (
  querySnapshot: firebase.firestore.QuerySnapshot
): Article[] => {
  const articles: Article[] = [];

  querySnapshot.forEach(doc => {
    const data = doc.data();

    articles.push({
      id: doc.id,
      title: data.title,
      description: data.description,
      link: data.link,
      picture: data.picture,
      tag: data.tag,
      type: data.type,
      keywords: data.keywords,
      created: data.created,
    });
  });

  return articles;
};
