import { ArticleTag } from '../../models/article-tag';
import { FilterActions, FilterAction } from '../actions/filter';
import { ArticleType } from '../../models/article-type';

export interface State {
  tags: ArticleTag[];
  keywords: string[];
  search: string;
  type: ArticleType;
}

export const initialState = {
  tags: [ArticleTag.Dev],
  keywords: [],
  search: '',
  type: ArticleType.Article,
};

export const filterReducer = (
  state: State = initialState,
  action: FilterActions
): State => {
  switch (action.type) {
    case FilterAction.FILTER_CHANGE_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case FilterAction.FILTER_CHANGE_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case FilterAction.FILTER_UPDATE_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case FilterAction.FILTER_ADD_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.concat(action.payload),
      };
    case FilterAction.FILTER_REMOVE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.filter(keyword => keyword !== action.payload),
      };
    default:
      return state;
  }
};
