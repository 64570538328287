import React from 'react';

import './footer.scss';

export function FooterComponent() {
  return (
    <div className="page-bottom">
      <p>UX-Republic 2019 ©</p>
    </div>
  );
}
