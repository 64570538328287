import { ArticleTag } from '../../models/article-tag';
import { ArticleType } from '../../models/article-type';

export enum FilterAction {
  FILTER_CHANGE_TAGS = 'FILTER_CHANGE_TAGS',
  FILTER_CHANGE_TYPE = 'FILTER_CHANGE_TYPE',
  FILTER_UPDATE_SEARCH = 'FILTER_UPDATE_SEARCH',
  FILTER_ADD_KEYWORD = 'FILTER_ADD_KEYWORD',
  FILTER_REMOVE_KEYWORD = 'FILTER_REMOVE_KEYWORD',
}

export interface FilterChangeTags {
  type: FilterAction.FILTER_CHANGE_TAGS;
  payload: ArticleTag[];
}

export interface FilterChangeType {
  type: FilterAction.FILTER_CHANGE_TYPE;
  payload: ArticleType;
}

export interface FilterUpdateSearch {
  type: FilterAction.FILTER_UPDATE_SEARCH;
  payload: string;
}

export interface FilterAddKeyword {
  type: FilterAction.FILTER_ADD_KEYWORD;
  payload: string;
}

export interface FilterRemoveKeyword {
  type: FilterAction.FILTER_REMOVE_KEYWORD;
  payload: string;
}

export type FilterActions =
  | FilterChangeTags
  | FilterChangeType
  | FilterUpdateSearch
  | FilterAddKeyword
  | FilterRemoveKeyword;
